import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, take } from 'rxjs';

import { FullPrescriptionHistoryComponent } from '@app/features/prescription-history/components/full-prescription-history/full-prescription-history.component';
import { PrescriptionHistoryTabs } from '@app/features/prescription-history/shared/prescription-history.type';
import { Renewal } from '@app/features/renewals/shared/renewals.type';
import { RxCart } from '@app/modules/rx-cart';
import { DialogService } from '@app/shared/components/dialog';

export const MARK_RX_CART_READY_TO_SIGN = gql`
  mutation MarkRxCartReadyToSign($rxCartId: ID!) {
    markRxCartReadyToSign(input: { rxCartId: $rxCartId }) {
      success
    }
  }
`;

const mapToRxCartReadyToSignResult = ({
  data: {
    markRxCartReadyToSign: { success },
  },
}): boolean => success;

@Component({
  selector: 'omg-pdmp-dialogue-banner',
  templateUrl: './pdmp-dialogue-banner.component.html',
  styleUrls: ['./pdmp-dialogue-banner.component.scss'],
})
export class PdmpDialogueBannerComponent implements OnChanges {
  @Input() cartId: number;
  @Input() cartItems: RxCart['cartItems'] | Array<Renewal>;
  @Output() confirmedReportViewed = new EventEmitter<boolean>();

  isChecked: boolean;

  constructor(private dialogService: DialogService, private apollo: Apollo) {}

  ngOnChanges(changes: SimpleChanges): void {
    // reset PDMP confirmation if the number of controlled substances in rxCart changes
    const cartItemsChanges = changes['cartItems'];
    if (cartItemsChanges.previousValue) {
      const previousControlledSubstanceCount = cartItemsChanges.previousValue.filter(
        item => item.dispensableControlledSubstance,
      ).length;
      const currentControlledSubstanceCount = cartItemsChanges.currentValue.filter(
        item => item.dispensableControlledSubstance,
      ).length;

      if (
        previousControlledSubstanceCount !== currentControlledSubstanceCount
      ) {
        this.isChecked = false;
      }
    }
  }

  onShowFullRxHistoryClick() {
    const dialogRef = this.dialogService.open(
      FullPrescriptionHistoryComponent,
      {
        autoFocus: true,
        disableClose: false,
      },
    );

    if (dialogRef) {
      dialogRef.componentInstance.currentHistoryTab =
        PrescriptionHistoryTabs.pdmp.id;
      dialogRef.componentInstance.rxCartId = this.cartId;
    }
  }

  onConfirmReportViewedChange() {
    if (this.isChecked) {
      this.markAsReadyToSign(this.cartId)
        .pipe(take(1))
        .subscribe(result => {
          this.isChecked = result;
          this.confirmedReportViewed.emit(this.isChecked);
        });
    } else {
      this.confirmedReportViewed.emit(false);
    }
  }

  private markAsReadyToSign = cartId =>
    this.apollo
      .use('onelife')
      .mutate({
        mutation: MARK_RX_CART_READY_TO_SIGN,
        variables: { rxCartId: cartId },
      })
      .pipe(map(mapToRxCartReadyToSignResult));
}
