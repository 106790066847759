<div om-layout="vertical"
     om-layout-gutter
     class="pdmp-dialogue-banner padding-normal">
  <a (click)="onShowFullRxHistoryClick()"
     class="pdmp-report-link om-link -plain-text"
     data-cy="pdmp-show-report-btn">
    View Prescription Drug Monitoring Program (PDMP) Report
  </a>
  <omg-checkbox label="I have consulted the report"
                plainLabel="true"
                [(ngModel)]="isChecked"
                (ngModelChange)="onConfirmReportViewedChange()"
                class="pdmp-dialogue-banner-confirm">
  </omg-checkbox>
</div>
